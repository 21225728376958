"use client";

import dynamic from "next/dynamic";
import { Suspense, useContext, useEffect, useRef } from "react";
import { ModalContext } from "@/context/ModalContext";
import { StorageService } from "@/helpers/storage.service";
import { pushToDataLayer } from "@/helpers/gtag";
import { OTPContextProvider } from "./context";
import { getGlobalStateValue } from "@/context/utils";
import { IModalActions, ModalTypes } from "@/types/modal";

import ConnectModal from "@organisms/modal/ConnectModal/ConnectModal";
import DownloadBrochure from "@organisms/modal/DownloadBrochure";
import LoginModal from "@organisms/LoginModal";

import Modal from  "@molecules/Modal"
import { ModalDialog } from  "@molecules/Modal"

const MediaFormSection = dynamic(() => import("@organisms/MediaFormSection/MediaFormSection"), { ssr: false });
const EmiModalContent = dynamic(() => import("@organisms/FeesSection/EmiModalContent"), { ssr: false });
const LandingPageConnectModalTempleteOne = dynamic(() => import("@organisms/modal/ConnectModalFormLPTempleteOne/LandingPageConnectModalTempleteOne"), { ssr: false });

const ConnectModalSponsoredCourses = dynamic(() => import("@organisms/modal/ConnectModalSponsoredCourses/ConnectModalSponsoredCourses"), { ssr: false });

const ConnectModalLP = dynamic(() => import("@/components/organisms/modal/ConnectModalLP/ConnectModalLP"), {
  ssr: false,
});

const VideoPopup = dynamic(
  () => import("@organisms/VideoPopup/VideoPopup"),
  {
    ssr: false,
  }
);
const CallUsModal = dynamic(
  () => import("@organisms/modal/CallUsModal"),
  {
    ssr: false,
  }
);
const CallUsModal_V2 = dynamic(
  () => import("@organisms/modal/CallUsModalV2"),
  {
    ssr: false,
  }
);
const CallingSticky_Modal = dynamic(
  () => import("@organisms/modal/CallingSticky_Modal"),
  {
    ssr: false,
  }
);

const DownloadBrochureLP = dynamic(
  () => import("@organisms/modal/DownloadBrochureLP/DownloadBrochureLP"),
  {
    ssr: false,
  }
);

const PreDegreeModal = dynamic(
  () => import("@organisms/modal/PreDegreeModal/PreDegreeModal"),
  {
    ssr: false,
  }
);

const RegistrationForm = dynamic(
  () =>
    import("@organisms/modal/RegistrationForm/RegistrationForm"),
  {
    ssr: false,
  }
);

const registrationModalData = {
  Heading:
    "To know more about our career services, register now and our team will reach out to you",
  Image: {
    alt: "Registration form",
    enabled: true,
    source: {
      data: {
        attributes: {
          url: "https://amity-strapi-file-uploads.s3.ap-south-1.amazonaws.com/shaking_hand_speaker_f89bb6a967.png",
        },
      },
    },
  },
};

const renderModalContent = ({
  modalType,
  modalData,
  isMobile,
  onClose,
}: {
  modalType: ModalTypes
  modalData: any
  isMobile: boolean
  onClose: any
}) => {
  const {
    url = "",
    programTitle = "",
    associatedPrograms = [],
    isLandingPage = false,
    specializationText,
    Custom10PgType="",
    isNewLeadForm = true,
    popup={}
  } = modalData || {}

  switch (modalType) {
    case ModalTypes.VideoPopup:
      return <VideoPopup url={url} />;
    case ModalTypes.LoginModal:
      return <LoginModal isMobile={isMobile} />;

    case ModalTypes.ConnectModalLP:
      return (
        <ConnectModalLP
          {...modalData}
          isMobile={isMobile}
          associatedPrograms={associatedPrograms}
          isLandingPage={isLandingPage}
          isNewLeadForm={isNewLeadForm}
          popup={popup}
        />
      );

    case ModalTypes.ConnectModal:
      return (
        <Suspense fallback={null}>
          <ConnectModal
            {...modalData}
            isMobile={isMobile}
            associatedPrograms={associatedPrograms}
            isLandingPage={isLandingPage}
            isNewLeadForm={isNewLeadForm}
            popup={popup}
          />
        </Suspense>
      );

    case ModalTypes.LandingPageConnectModalTempleteOne:
      return (
        <Suspense fallback={null}>
          <LandingPageConnectModalTempleteOne
            {...modalData}
            isMobile={isMobile}
            associatedPrograms={associatedPrograms}
            isLandingPage={isLandingPage}
            isNewLeadForm={isNewLeadForm}
            popup={popup}
          />
        </Suspense>
      );
     
    case ModalTypes.ConnectModalFormSponsoredCourses:
      return (
        <Suspense fallback={null}>
          <ConnectModalSponsoredCourses
            {...modalData}
            isMobile={isMobile}
            associatedPrograms={associatedPrograms}
            isLandingPage={isLandingPage}
            isNewLeadForm={isNewLeadForm}
            popup={popup}
          />
          </Suspense>
        );  

    case ModalTypes.DownloadBrochure:
      return (
        <Suspense fallback={null}>
          <DownloadBrochure
            isMobile={isMobile}
            programTitle={programTitle}
            onClose={onClose}
            specializationText={specializationText}
            Custom10PgType={Custom10PgType}
          />
        </Suspense>
      );
    case ModalTypes.PreDegreeModal:
      return (
        <Suspense fallback={null}>
          <PreDegreeModal {...modalData} isMobile={isMobile} />
        </Suspense>
      );

    case ModalTypes.DownloadBrochureLP:
      return (
        <Suspense fallback={null}>
          <DownloadBrochureLP
            {...modalData}
            isMobile={isMobile}
            programTitle={programTitle}
            onClose={onClose}
            specializationText={specializationText}
            Custom10PgType={Custom10PgType}
          />
        </Suspense>
      );
      
    case ModalTypes.CallUsModal:
      return <CallUsModal isMobile={isMobile}  />;

      case ModalTypes.CallUsModal_V2:
        return <CallUsModal_V2 isMobile={isMobile} />;
    case ModalTypes.CallingSticky_Modal:
          return <CallingSticky_Modal associatedPrograms={associatedPrograms} isMobile={isMobile} />;
    case ModalTypes.emiModal:
      return <EmiModalContent Fees={modalData} />;
    case ModalTypes.RegistrationForm:
      return (
        <MediaFormSection {...registrationModalData} isMobile={false}>
          <RegistrationForm />
        </MediaFormSection>
      );
  }
}

const CommonModals = ({
  isMobile,
}: {
  isMobile: boolean
}) => {
  const { modalState, modalDispatch } = useContext(ModalContext)
  const { isModalOpen, isModalForceClose, modalType, modalData } = modalState;

  const isSessionModalActive = StorageService.get("timeConnectModal")

  useEffect(()=>{
    const handleQueryBasedModal = async () => {
      const getUrlParams = new URLSearchParams(window.location.search);
      const modalId = getUrlParams.get('modalId') || "";
      if (ModalTypes[modalId]) {
        modalDispatch({
          type: IModalActions.OpenModal,
          data: { modalType: ModalTypes[modalId], isModalOpen: true }
        });
      }
    };
    handleQueryBasedModal();
  },[]);

  useEffect(() => {
    const pathName = window.location.pathname;
    const modalElement = document.querySelector("div#_modal");

    const timer = setTimeout(() => {
      if (
        !isModalOpen &&
        !isModalForceClose &&
        !isSessionModalActive &&
        (pathName.includes("master-of-business-administration-online") ||
          pathName.includes("master-of-computer-applications-online") ||
          pathName.includes("bachelor-of-computer-applications-online") ||
          pathName.includes("career-services") ||
          pathName.includes("amity-online-advantage") ||
          pathName.includes("bachelor-of-business-administration-online")) &&
        !(getGlobalStateValue("pageType") === "Certificate Page") && 
        pathName !== '/certifications'

      ) {
        const tempisSessionModalActive = StorageService.get("timeConnectModal");
        if (!modalElement.childElementCount && !tempisSessionModalActive) {
          modalDispatch({
            type: IModalActions.UpdateModal,
            data: { modalType: ModalTypes.CallUsModal_V2, isModalOpen: true },
          });
          StorageService.set("timeConnectModal", true);
          clearTimeout(timer);
        }
      } else if (
        !isModalOpen &&
        !isModalForceClose &&
        !isSessionModalActive &&
        !pathName.includes("lp-nw") &&
        !pathName.includes("referral") &&
        !(getGlobalStateValue("pageType") === "Certificate Page") &&
        pathName !== '/certifications'
      ) {
        const tempisSessionModalActive = StorageService.get("timeConnectModal");
        if (!modalElement.childElementCount && !tempisSessionModalActive) {
          modalDispatch({
            type: IModalActions.UpdateModal,
            data: { modalType: ModalTypes.CallingSticky_Modal, isModalOpen: true },
          });
          StorageService.set("timeConnectModal", true);
          clearTimeout(timer);
        }
      }
    }, 30000);

    return ()=>{
      clearInterval(timer);
    }
  }, []);


  useEffect(()=>{
    if(isModalOpen) {
      StorageService.set("timeConnectModal", true)
    }
  },[isModalOpen])

  const onCloseHandler = ()=>{

    modalDispatch({ type: IModalActions.CloseModal })

    setTimeout(()=>{
      if(getGlobalStateValue('pageType') === "Program Page" && modalType===ModalTypes.DownloadBrochure){
        pushToDataLayer({
          'event':  'thank_you_card_cta_interaction',
          'cta_text':'close',
          'section_name': getGlobalStateValue('parentSection') || "NA",
          'page_type': getGlobalStateValue('pageType')
        })
      }
    }, 2000);
   
  }

  return isModalOpen ? (
    <OTPContextProvider>
      <Modal modalId={modalType}>
        <ModalDialog
          onClose={onCloseHandler}
          modalType={modalType}
          size={modalType === ModalTypes.emiModal && "xl"}
          isMobile={isMobile}
        >
          {renderModalContent({
            modalType,
            modalData,
            isMobile,
            onClose: () => modalDispatch({ type: IModalActions.CloseModal }),
          })}
        </ModalDialog>
      </Modal>
    </OTPContextProvider>
  ) : null
}

export default CommonModals
