"use client";

import styles from "./Modal.module.scss";
import Button from "@/components/atoms/Button";
import FormGroup from "@/components/atoms/Form/FormGroup";
import TextInput, { SelectInput } from "@/components/atoms/Form/TextInput";
import { trackingDetails } from "@/api/getOtherFormDetails";
import { Field, Form } from "react-final-form";
import { useEffect, useState } from "react";
import { COUNTRY_CALLING_CODES } from "@/helpers/constants";
import {
  OTPContextWrapper,
  useOTPContext,
} from "@/components/sections/CommonModals/context";
import cn from "clsx";
import { ga_events_mapping, GA_EVENTS_PAYLOAD, GAEvent } from "@/helpers/gaEvents";
import { pushToDataLayer } from "@/helpers/gtag";
import { usePathname, useSearchParams } from "next/navigation";
import { getGAPayload } from "@/helpers/getGAPayload";
import { StorageService } from "@/helpers/storage.service";
import { getGlobalStateValue, fetchIpInfoFun } from "@/context/utils";
import ConsentFormPara from "@/components/common/utils/consentFormPara";
declare var programDetails: any;


const DownloadForm = (props) => {
  const { setFormValuesData=()=>{ }, gaOtherPayloadInfo={}, isOtpVerified, specializationText, Custom10PgType } = props;
  const searchParams = useSearchParams();
  const pathName = usePathname();
 
  const [message, setMessage] = useState(null);
  const [isLoading, loading] = useState(false);
  const [ipStackDetails, setIpStackDetails] = useState<any>({});
  const { handleSendOTP, isOTPLoading, setLeadResponse, leadError, setShowLoader, setLeadFailedComp } = useOTPContext();
  const {
    PROGRAM_SUBMIT_BASIC_DETAILS,
    PROGRAM_TAB_SUBMIT_BASIC_DETAILS,
    PROGRAM_CARD_SUBMIT_BASIC_DETAILS,
    PROGRAM_TAB_SUBMIT_OTP,
    PROGRAM_CARD_SUBMIT_OTP,
    PROGRAM_SUBMIT_OTP
  } = GAEvent

  const fetchIpStackDetails = () => {
    loading(true);
    return fetchIpInfoFun()
      .then((d) => {
        loading(false);
        return d;
      })
      .catch((e) => {
        loading(false);
      });
  };

  const otpVerifyAndSetResponse = () => {
      isOtpVerified(true);
      props.setMessage({
        title: "Thank you for your interest.",
        text: `Click to download the brochure.`,
      })
  }

  const handleSubmit = (values, form) => {
    const programDetailsGAInfo = StorageService.get("programDetailsGAInfo");
    const { isMbaPage = false, isNavbarClick } = programDetailsGAInfo || {};
    const gaConversionData = {
      enhanced_conversion : {
        emailId : values?.email || "",
        phoneNumber : values?.phone || "",
      },
      enhanced_conversion_data : {
        emailId : values?.email || "",
      }
    }
    const tracking = trackingDetails();
    const leadSquareApiCall = (payload: any) => {
        return fetch("/api/leadsquared.com/lead-submit", {
          method: "POST",
          body: JSON.stringify({
            ipstack: ipStackDetails,
            form: { ...values, PROGRAM_ID: programDetails.PROGRAM_ID, Custom6: specializationText || '', ...payload },
            meta: {
              ...tracking,
              utm_medium: tracking?.utm_medium || "download_brochure",
              PROGRAM_ID: programDetails.PROGRAM_ID,
              program: programDetails.PROGRAM,
              programLevel: programDetails.PROGRAM_LEVEL,
              mode: programDetails.Mode,
            },
          }),
        });
  }
    
    const submitGaCb = () => {
      if (ga_events_mapping?.[pathName] || isMbaPage) {
        let eventPayload:any;
        if(isNavbarClick!==undefined){
          eventPayload = isNavbarClick
          ? GA_EVENTS_PAYLOAD.get(PROGRAM_TAB_SUBMIT_BASIC_DETAILS)
          : GA_EVENTS_PAYLOAD.get(PROGRAM_CARD_SUBMIT_BASIC_DETAILS);
        }else{
          eventPayload = GA_EVENTS_PAYLOAD.get(PROGRAM_SUBMIT_BASIC_DETAILS);
        }
        const gaData:any = {
          ...eventPayload,
          ...gaConversionData
        };
        const gaPayload = getGAPayload(gaData,searchParams,gaOtherPayloadInfo);
        pushToDataLayer(gaPayload);
      }
    };

    const verifyOtpGaCb =async () => {
      if (ga_events_mapping?.[pathName] || isMbaPage) {
        let eventPayload:any;
        if(isNavbarClick!==undefined){
          eventPayload = isNavbarClick 
          ? GA_EVENTS_PAYLOAD.get(PROGRAM_TAB_SUBMIT_OTP)
          : GA_EVENTS_PAYLOAD.get(PROGRAM_CARD_SUBMIT_OTP);
        }else{
          eventPayload = GA_EVENTS_PAYLOAD.get(PROGRAM_SUBMIT_OTP);
        }
        const gaData:any = {
          ...eventPayload,
          ...gaConversionData
        };
        const gaPayload = getGAPayload(gaData,searchParams,gaOtherPayloadInfo);
        pushToDataLayer(gaPayload);
        setFormValuesData(values);
      }

      otpVerifyAndSetResponse();
    };

    const cb = () => {
      return new Promise((resolve, reject) => {
      loading(true);
      const downloadBrochureApiCall = (status:boolean)=> fetch("/api/download-brochure", {
        method: "POST",
        body: JSON.stringify({
          ipstack: ipStackDetails,
          form: values,
          meta: {
            ...tracking,
            utm_medium: tracking?.utm_medium || "download_brochure",
            PROGRAM_ID: programDetails.PROGRAM_ID,
            program: programDetails.PROGRAM,
            programLevel: programDetails.PROGRAM_LEVEL,
            mode: programDetails.Mode,
          },
          status
        }),
      });
      const setDownloadBrochData = async (status:boolean) => {
        try{
          const counsellorResponse = await downloadBrochureApiCall(status);
          const counsellorData = await counsellorResponse.json();
          return counsellorData
        }catch(err){
          console.error("Error in councelor udpate data", err)
        }
      }

      async function performApiCalls() {
        try {
          loading(true);
          let leadSquareResponse;
          try {
            const response = await leadSquareApiCall({Custom5 : "Not Verified", Custom10: Custom10PgType || "" });
            if (typeof response === 'string') {
              leadSquareResponse = JSON.parse(response);
            } else {
              leadSquareResponse = await response.json();
            }
            if (leadSquareResponse?.success) {
              const resp = leadSquareResponse?.response || {};
              props.setFormResponse({
                ID: resp?.ID,
                link: resp?.URL,
                ProspectId: resp?.ProspectId,
                // message: resp?.Message,  
              })
              pushToDataLayer({
                event: "lead_submit",
                cta_text: "Submit",
                lead_starting_cta: getGlobalStateValue('leadStartingCta') || "NA",
                lead_starting_url : window.location.href || "NA",
                lead_id : resp?.ProspectId || "NA",
                section_name : getGlobalStateValue('parentSection') || "NA",
                status: "success",
                error_message: "NA",
                lead_type: "lead_submit_1",
                page_type : "Program Page",
                page_url : window?.location?.href || "NA"
              });

              window.webengage.user.login(resp?.ProspectId);

              window.webengage.user.setAttribute('we_email', values.emailId);
              window.webengage.user.setAttribute('we_first_name', values.fullName);
              window.webengage.user.setAttribute('we_phone', values.countryCode + values.phoneNumber);
            

              window.webengage.track("Lead Form Filled", {
                  "Student Nationality": values.countryCode == "+91" ? "National" : "International",
                  "First Name": values.fullName,
                  "Phone Number": values.countryCode + values.phone,
                  "Email ID": values.email,
                  "Form Type": "download brochure",
                   "Section Name": getGlobalStateValue('parentSection')
              });
              
              if (typeof setLeadResponse === "function") {
                setLeadResponse(resp);
              }
              if(values?.countryCode!=="+91"){
                otpVerifyAndSetResponse();
              }
              resolve(resp);

              await setDownloadBrochData(true)
            }
          } catch (error) {
            console.error("LeadSquare API call failed:", error);
            if(values?.countryCode!=="+91"){
              otpVerifyAndSetResponse();
              setShowLoader(true);
            }
            const downloadBrochApiData = await setDownloadBrochData(false)
          }

          if(!leadSquareResponse?.success){
            if(values?.countryCode!=="+91"){
                otpVerifyAndSetResponse();
                setShowLoader(true);
                setLeadFailedComp(false)
              }
            const downloadBrochApiData = await setDownloadBrochData(false)
          }
          resolve(leadSquareResponse?.response);
        } catch (error) {
          console.error("Error:", error);
          pushToDataLayer({
            event: "lead_submit",
            cta_text: "Submit",
            lead_starting_cta: getGlobalStateValue('leadStartingCta') || "NA",
            lead_starting_url : window.location.href || "NA",
            lead_id : "NA",
            section_name : getGlobalStateValue('parentSection') || "NA",
            status: "fail",
            error_message: error,
            lead_type: "lead_submit_1",
            page_type : "Program Page",
            page_url : window?.location?.href || "NA"
          });
          const errorMessage = "Error occurred.";
          setMessage(errorMessage);
          reject(error);
        } finally {
          loading(false);
        }
      }
      performApiCalls();
      });
    };

    

    handleSendOTP(values, cb, submitGaCb, verifyOtpGaCb);
    
  };

  useEffect(() => {
    fetchIpStackDetails().then((d) => {
      setIpStackDetails(d);
    });
  }, []);

  const handleFieldBlur = (fieldName: string, value: string, error: string | undefined) => {
    pushToDataLayer({
      'event': 'form_filled',
      'field_name': fieldName,
      'option_selected': value || "NA",
      'error_message': error || 'NA',
      'section_name': getGlobalStateValue('parentSection') || "NA",
      'page_type': getGlobalStateValue('pageType'),
      'page_url' : window?.location?.href || "NA"
    });
  };

  return (
    <Form
      initialValues={{
        countryCode: `+${ipStackDetails?.location?.calling_code}` || "+91",
        consent: "1",
      }}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: any = {};
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(values.email)) {
          errors.email = "Please enter valid email";
        }

        const nameRegex = /^[a-zA-Z][a-zA-Z\s]{2,31}$/;
        if (!values?.name || !nameRegex.test(values.name)) {
          errors.name = "Please enter valid name";
        }

        const regex =
          values.countryCode === "+91"
            ? /^[1-9][0-9]{9}$/
            : /^[1-9][0-9]{6,14}$/;
        if (!regex.test(values.phone)) {
          errors.phone = "Please enter valid phone number";
        }

        if (!values.consent) {
          errors.consent = "Please accept the consent";
        }

        return errors;
      }}
    >
      {(props) => (
        <div>
          {!leadError && <div
            className={`text-blu font-baskervville font-normal capitalize mb-3 lg:mb-4 ${styles.header}`}
          >
            Download Brochure
          </div>}
          <OTPContextWrapper>
            <form
              className={`${styles.content} py-4 flex flex-col`}
              onSubmit={props.handleSubmit}
            >
              {message ? <div className="text-red-800">{message}</div> : null}

              <div className={`text-sec font-railway font-medium ${styles.text}`}>
                Fill in your details here and download a detailed brochure of
                the program
              </div>
              <Field name="name">
                {({ input, meta }) => {
                  return (
                    <FormGroup
                      label="Name"
                      required
                      className="font-railway text-secondary-gray"
                      error={meta.touched && meta.error}
                    >
                      <TextInput
                        {...input}
                        name="email"
                        placeholder="Enter your full name"
                        onBlur={(e) => {
                          input.onBlur(e);
                          handleFieldBlur('name', "", meta.error);
                        }}
                      />
                    </FormGroup>
                  );
                }}
              </Field>
              <div className="flex items-start">
                <Field name="countryCode">
                  {({ input, meta }) => {
                    return (
                      <FormGroup
                        label="Country Code"
                        required
                        className="font-railway mr-2 mt-[1.125rem] lg:mt-[0.9166rem] basis-1/5"
                        error={meta.touched && meta.error}
                      >
                        <SelectInput
                          input={input}
                          meta={meta}
                          options={COUNTRY_CALLING_CODES}
                          className="bg-white text-secondary-gray"
                        />
                      </FormGroup>
                    );
                  }}
                </Field>
                <Field name="phone">
                  {({ input, meta }) => {
                    return (
                      <FormGroup
                        label="Phone Number"
                        required
                        className="font-railway flex-1 overflow-auto text-secondary-gray"
                        error={meta.touched && meta.error}
                      >
                        <TextInput 
                          {...input} 
                          placeholder="Enter your no." 
                          onBlur={(e) => {
                            input.onBlur(e);
                            handleFieldBlur('phone', "", meta.error);
                          }}
                        />
                      </FormGroup>
                    );
                  }}
                </Field>
              </div>
              <Field name="email">
                {({ input, meta }) => {
                  return (
                    <FormGroup
                      label="Email Id"
                      required
                      className="font-railway mt-3 text-secondary-gray"
                      error={meta.touched && meta.error}
                    >
                      <TextInput
                        {...input}
                        name="email"
                        placeholder="Enter your email"
                        onBlur={(e) => {
                          input.onBlur(e);
                          handleFieldBlur('email',"", meta.error);
                        }}
                      />
                    </FormGroup>
                  );
                }}
              </Field>
              <div className="flex justify-center items-start mt-3">
                <div className="mt-2">
                  <Field
                    name="consent"
                    type="radio"
                    component="input"
                    value="1"
                    required
                    defaultChecked={true}
                    defaultValue="1"
                  />
                  {/* <input type="radio" name={"consent"} required defaultChecked /> */}
                  {/* </Field> */}
                </div>
                <p
                  className={`ml-3 text-sec font-railway font-medium mb-2 ${styles.text}`}
                >
                  <ConsentFormPara/>
                </p>
              </div>
              <Button
                className={cn("mt-auto", {
                  ["!bg-[#E7E7E7] !border-stone-500 !text-stone-500"]:
                    isLoading || isOTPLoading || props.hasValidationErrors,
                })}
                type="submit"
                disabled={
                  isLoading || isOTPLoading || props.hasValidationErrors
                }
              >
                SUBMIT
              </Button>
              {/* </div> */}
            </form>
          </OTPContextWrapper>
        </div>
      )}
    </Form>
  );
};

export default DownloadForm;