"use client";

import s from "./LoginModal.module.scss";

import Button from "@/components/atoms/Button";
import FormGroup from "@/components/atoms/Form/FormGroup";
import TextInput, { PasswordInput } from "@/components/atoms/Form/TextInput";
import { getGlobalStateValue } from "@/context/utils";
import { pushToDataLayer } from "@/helpers/gtag";
import { useState } from "react";
import { Field, Form } from "react-final-form";

const LoginWithEmail = ({ setTab }) => {
  const [message, setMessage] = useState(null);
  const [isLoading, loading] = useState(false);

  const handleSubmit = (e) => {
    loading(true);
    fetch("/api/login", {
      method: "post",
      body: JSON.stringify(e),
    })
      .then((d) => d.json())
      .then((d) => {
        if (d.redirectUrl) {

          pushToDataLayer({
            "event": "student_login_submit",
            "status": "student_login_success",
            "cta_text": "SUBMIT",
            "page_type": getGlobalStateValue("pageType"),
            "page_url": location.href
          });

          window.webengage.track("Student Login Form Submitted",{
            "Login Type": "Login with email",
            "Email ID": e.email,
            "Phone Number": "",
            "Section Name": "header",
          })
      
          window.location.href = d.redirectUrl;

        } else {
      
          pushToDataLayer({
            "event": "student_login_submit",
            "status": "student_login_fail",
            "cta_text": "SUBMIT",
            "page_type": getGlobalStateValue("pageType"),
            "page_url": location.href
          });

          window.webengage.track("Student Login Form Submitted",{
            "Login Type": "Login with email",
            "Email ID": e.email,
            "Phone Number": "",
            "Section Name": "header",
          })

          setMessage(d.message);
        }
      })
      .catch((e) => {
        
      })
      .finally(() => {
        loading(false);
      });
  };

  const validate = (values) => {
    const emailRegex = /^[a-zA-Z0-9+_.-]+@(?:[a-zA-Z0-9.-]+\.)+([a-zA-Z]{2,})$/;
    const errors: any = {};
    if (!emailRegex.test(values.email)) {
      errors.email = "Required";
    }
    if (!values.password) {
      errors.password = "Required";
    }

    return errors;
  };
  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {(props) => (
        <form
          className={`${s.content} py-4 flex flex-col`}
          onSubmit={props.handleSubmit}
        >
          <div
            className={`text-blu font-baskervville font-normal capitalize mb-3 lg:mb-4 ${s.header}`}
          >
            Student Login
          </div>
          <div
            className={`text-sec font-railway font-medium mb-7 lg:mb-9 ${s.text}`}
          >
            Enter your details here to login to your account
          </div>
          <div className="flex gap-2 lg:flex-row gap-y-2 lg:gap-x-4 mb-9">
            <Button
              bg="s"
              type="button"
              buttonProps={{
                onClick: ()=>setTab("withEmail"),
              }}
            >
              Login with email
            </Button>
            <Button
              bg="o"
              type="button"
              buttonProps={{
                onClick: ()=>setTab("withOTP"),
              }}
            >
              Login with otp
            </Button>
          </div>
          {message ? <div className="text-red-800">{message}</div> : null}
          <Field name="email">
            {({ input, meta }) => {
              return (
                <FormGroup
                  label="Email Id/ Form Id"
                  required
                  className="font-railway"
                >
                  <TextInput
                    {...input}
                    name="email"
                    placeholder="Enter Email Id"
                  />
                </FormGroup>
              );
            }}
          </Field>
          <Field name="password">
            {({ input, meta }) => {
              return (
                <FormGroup
                  label="Password"
                  required
                  className="font-railway"
                  helperContent={
                    <button className="cursor-pointer" onClick={()=>setTab("forgetPass")} >
                      Forgot Password?
                    </button>
                  }
                >
                  <PasswordInput {...input} placeholder="Enter Password " />
                </FormGroup>
              );
            }}
          </Field>
          <div className="mb-4"></div>
          <Button
            type="submit"
            disabled={props.hasValidationErrors || isLoading}
          >
            SUBMIT
          </Button>
          <div className={s.referBtn}>
          <a href="/referral">
            <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 512 512"><path d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z"/></svg>
            Refer & Earn</a>
          </div>
          {/* </div> */}
        </form>
      )}
    </Form>
  );
};

export default LoginWithEmail;
