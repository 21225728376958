"use client"
import React, { useEffect, useState } from 'react'

const ConsentFormPara = () => {
  const [privacyPolicyURL, setPrivacyPolicyURL] = useState('');

  useEffect(() => {
    setPrivacyPolicyURL(`${window.location.origin}/privacy-policy`);
  }, []);

  return (
    <span>
        I authorise Amity University Online and its associates to contact me
        with updates & notifications via Email, SMS, WhatsApp, and Voice call as
        per the <a
        href={privacyPolicyURL}
        style={{textDecoration:'underline',textDecorationColor:'inherit',cursor:'pointer'}}>Privacy Policy</a>. This consent will override any registration for
        DNC / NDNC.
    </span>
  )
}

export default ConsentFormPara;
