"use client";

import s from "./LoginModal.module.scss";

import Button from "@/components/atoms/Button";
import FormGroup from "@/components/atoms/Form/FormGroup";
import TextInput from "@/components/atoms/Form/TextInput";
import { useState } from "react";
import { Field, Form } from "react-final-form";

const ForgotPassword = (props) => {
  const [message, setMessage] = useState(null);
  const [isLoading, loading] = useState(false);

  const handleSubmit = (e) => {
    loading(true);
    fetch("/api/login/forgotpassword", {
      method: "post",
      body: JSON.stringify(e),
    })
      .then((d) => d.json())
      .then((d) => {
        if (d.success) {
          props.setMessage({
            title: "Your password has been sent to your registered Email id",
            text: "Please check your email and log in again.",
          });

          window.webengage.track("Student Login Form Submitted", {
            "Login Type": "Forgot Password",
            "Email ID": e.email,
            "Section Name": "header",
          })

        } else {
          setMessage(d.message);

          window.webengage.track("Student Login Form Submitted", {
            "Login Type": "Forgot Password",
            "Email ID": e.email,
            "Section Name": "header",
          });

        }
      })
      .catch((e) => {
        console.log(e.response.data);

        window.webengage.track("Student Login Form Submitted", {
          "Login Type": "Forgot Password",
          "Email ID": e.email,
          "Phone Number": "",
          "Section Name": "header",
        });

      })
      .finally(() => {
        loading(false);
      });
  };
  return (
    <Form onSubmit={handleSubmit} setTab={props.setTab}>
      {(props:any) => (
        <form
          className={`${s.content} py-4 flex flex-col`}
          onSubmit={props.handleSubmit}
        >
          <div
            className={`text-blu font-baskervville font-normal capitalize mb-3 lg:mb-4 ${s.header}`}
          >
            Forgot Password
          </div>
          <div
            className={`text-sec font-railway font-medium mb-7 lg:mb-9 ${s.text}`}
          >
            You can reset your password here
          </div>

          {message ? <div className="text-red-800">{message}</div> : null}
          <Field name="email">
            {({ input, meta }) => {
              const { setTab } = props
              return (
                <FormGroup
                  label="Email Id"
                  required
                  className="font-railway"
                  helperContent={
                    <button onClick={()=>setTab("withEmail")}>Back to login?</button>
                  }
                >
                  <TextInput {...input} />
                </FormGroup>
              );
            }}
          </Field>

          <Button className="mt-auto" type="submit" disbled={isLoading}>
            RESET
          </Button>
          {/* </div> */}
        </form>
      )}
    </Form>
  );
};

export default ForgotPassword;
